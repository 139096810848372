
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as api from '../../../../store/apiClient';
import { clickHandler, formatLaptime, mapUtcDate } from '../../../../utils/util';
import ApiError from '../../../global/apiError';
import { DateFormat } from '../../../../store/pages/venues/types';
import Loading from '../../../global/loading';

interface GetLeaderboardResponse {
    leaderboardName: string;
    leaderboard: LeaderboardCompetitor[];
}

interface LeaderboardCompetitor {
    position: number;
    customerEventSessionResultId: string;
    name: string;
    lapTimeInMs: number;
    timeSet: Date | null;
}

interface ViewLeaderboardProps {
    venueId: string;
    dateFormat: DateFormat;
    leaderboardId: string;
    close: () => void;
    logout: () => void;
}

interface ViewLeaderboardState {
    loading: boolean;
    leaderboardName: string;
    leaderboard: LeaderboardCompetitor[];
    loadError: api.ApiError | null;
    invalidating: boolean;
    invalidationError: api.ApiError | null;
    showConfirmRemoveOverlay: boolean;
    leaderboardCompetitor: LeaderboardCompetitor | null;
}

class ViewLeaderboard extends React.Component<ViewLeaderboardProps, ViewLeaderboardState> {

    constructor(props: ViewLeaderboardProps) {
        super(props);

        this.state = {
            loading: true,
            leaderboardName: '',
            leaderboard: [],
            loadError: null,
            invalidating: false,
            invalidationError: null,
            showConfirmRemoveOverlay: false,
            leaderboardCompetitor: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        this.loadLeaderboard();
    }

    loadLeaderboard = () => {
        const { venueId, leaderboardId, logout } = this.props;

        this.setState({ loading: true, loadError: null, leaderboard: [], leaderboardName: '' }, () =>
            api.getWithAuth<GetLeaderboardResponse>(`api/v1/leaderboard/${venueId}/view/${leaderboardId}`, logout)
                .subscribe(
                    resp => this.setState({
                        loading: false,
                        leaderboardName: resp.leaderboardName,
                        leaderboard: resp.leaderboard.map(l => ({ ...l, timeSet: mapUtcDate(l.timeSet) })),
                        loadError: null
                    }),
                    err => this.setState({ loading: false, leaderboard: [], leaderboardName: '', loadError: err })));
    }

    invalidateLaptime = (leaderboardCompetitor: LeaderboardCompetitor) => {
        this.setState({ showConfirmRemoveOverlay: true, leaderboardCompetitor: leaderboardCompetitor })
    }

    confirmInvalidateLaptime = () => {
        const { venueId, logout } = this.props;
        const { leaderboardCompetitor } = this.state;

        if (leaderboardCompetitor) {
            this.setState({ invalidating: true, invalidationError: null }, () =>
                api.putWithAuth(`api/v1/leaderboard/${venueId}/invalidate/${leaderboardCompetitor.customerEventSessionResultId}`, {}, logout)
                    .subscribe(
                        resp => this.setState({
                            invalidating: false,
                            invalidationError: null,
                            showConfirmRemoveOverlay: false,
                            leaderboardCompetitor: null,
                        }, this.loadLeaderboard),
                        err => this.setState({ invalidating: false, invalidationError: err, showConfirmRemoveOverlay: false, leaderboardCompetitor: null })));
        }
    }
       

    render() {
        const { dateFormat, close } = this.props;
        const { loading, leaderboardName, leaderboard, loadError, invalidating, invalidationError, showConfirmRemoveOverlay, leaderboardCompetitor } = this.state;
        const { t } = this.context;

        if (loading) {
            return <div><Loading /></div>
        }

        if (loadError) {
            return <div><ApiError error={loadError} /></div>
        }

        return <div>
            <h2 className='leaderboard_title text-center'>{leaderboardName}</h2>

            {invalidationError ? <ApiError error={invalidationError} /> : null}

            <table className='table table-condensed'>
                <tbody>
                    {leaderboard
                        .sort((l1, l2) => l1.position - l2.position)
                        .map(l => <tr key={l.position}>
                            <td>{l.position}</td>
                            <td>{l.name}</td>
                            <td>{formatLaptime(l.lapTimeInMs)}</td>
                            <td>{l.timeSet ? l.timeSet.toShortDateString(dateFormat) : ''}</td>
                            <td><button className='btn btn-danger btn-sm' onClick={e => clickHandler(e, () => this.invalidateLaptime(l))} disabled={invalidating}>{t('Global:remove')}</button></td>
                        </tr>)}
                </tbody>
            </table>

            <p />
            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:close')}</button>
            </div>

            {showConfirmRemoveOverlay && leaderboardCompetitor ? <div className='overlay'>
                <div className='overlay-content text-center' style={({ maxWidth: '800px', margin: '0 auto', maxHeight: '300px' })}>
                    <h3>{t('Leaderbaord:confirmInvalidateCompetitorLaptimeHeader')}</h3>
                    <p>{t('Leaderbaord:confirmInvalidateCompetitorLaptimeMessage', { time: formatLaptime(leaderboardCompetitor.lapTimeInMs), name: leaderboardCompetitor.name})}</p>
                    <p />
                    <div className='btn-toolbar btn-toolbar-centered' style={{ maxHeight: '35px'}}>
                        <button className='btn btn-danger' onClick={e => clickHandler(e, this.confirmInvalidateLaptime)}>{t('Leaderbaord:confirmInvalidateCompetitorLaptimeAction')}</button>
                        <button className='btn btn-basic' onClick={e => clickHandler(e, () => this.setState({showConfirmRemoveOverlay: false}))}>{t('Global:cancel')}</button>
                    </div>
                </div>
            </div> : null}
        </div>
    }
};

export default ViewLeaderboard;