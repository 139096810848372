
// https://blog.angularindepth.com/how-to-reduce-action-boilerplate-90dc3d389e2b

import { ActionsObservable } from 'redux-observable';
import { Observable } from 'rxjs/Rx';
import * as epic from '../../epic';
import * as api from '../../apiClient';
import * as mt from './types'; 
import { IStore } from '../..';
import { mapLocalDateTime, mapUtcDate, parseLocalDateTime, parseUtcDate } from '../../../utils/util';
import { Time } from '../../global/types';

export type MembershipActions = mt.LoadMembershipTypes | mt.ReceiveMembershipTypes | mt.SaveMembershipType | mt.MembershipTypeSaved | mt.MembershipTypeSaveFailed | mt.EditMembershipType | mt.SearchMemberships | mt.MembershipsReceived;

export const actionCreators = {
    loadMembershipTypes: () => ({ type: mt.MembershipActionTypes.RequestMembershipTypes }),
    loadMembershipTypesComplete: (membershipTypes: mt.MembershipType[], err: api.ApiError | null) => ({ type: mt.MembershipActionTypes.ReceivedMembershipTypes, membershipTypes: membershipTypes, error: err }),
    editMembershipType: () => ({ type: mt.MembershipActionTypes.EditMembershipType }),
    saveMembershipType: (isNew: boolean, membershipType: mt.MembershipType, productImg: File | null, membershipImg: File | null, webShopImg: File | null) => ({ type: mt.MembershipActionTypes.SaveMembershipType, isNew: isNew, membershipType: membershipType, productImg: productImg, membershipImg: membershipImg, webShopImg: webShopImg }),
    membershipTypeSaved: (voucherProductId: string) => ({ type: mt.MembershipActionTypes.MembershipTypeSaved, voucherProductId: voucherProductId }),
    membershipTypeSaveFailed: (error: api.ApiError) => ({ type: mt.MembershipActionTypes.MembershipTypeSaveFailed, error: error }),
    searchMemberships: (membershipTypeId: string, search: string, pageNumber: number, pageSize: number) => ({ type: mt.MembershipActionTypes.SearchMemberships, membershipTypeId: membershipTypeId, search: search, pageNumber: pageNumber, pageSize: pageSize }),
    membershipsReceived: (memberships: mt.Membership[], maxPage: number, error: api.ApiError | null) => {
        return ({ type: mt.MembershipActionTypes.MembershipsReceived, memberships: memberships, maxPage: maxPage, error: error })
    }
}

interface IGetMembershipTypesResponse {
    membershipTypes: mt.MembershipType[];
}

interface ISaveMembershipTypeResponse {
    membershipTypeId: string;
}

interface ISearchMembershipsResponse {
    memberships: mt.Membership[];
    page: number;
    hasMorePages: boolean;
}

let counter = 0;

export const handleClientChange = (store: IStore) => [actionCreators.loadMembershipTypes]

//https://stackoverflow.com/questions/46481144/rxjs-how-to-retry-after-catching-and-processing-an-error-with-emitting-somethi

const loadMembershipTypes = () => Observable.defer(() => api.getJson<IGetMembershipTypesResponse>('api/v1/membership/membershipTypes'))
    .map(response => {
        return actionCreators.loadMembershipTypesComplete(response.membershipTypes.map(mt => ({
            ...mt,
            expiryDate: mapLocalDateTime(mt.expiryDate),
            pricing: mt.pricing.map(pr => ({
                ...pr,
                effectiveFrom: parseLocalDateTime(pr.effectiveFrom),
                effectiveTo: pr.effectiveTo ? parseLocalDateTime(pr.effectiveTo) : pr.effectiveTo,
                applyPriceFrom: pr.applyPriceFrom ? Time.parse(pr.applyPriceFrom) : pr.applyPriceFrom,
                applyPriceTo: pr.applyPriceTo ? Time.parse(pr.applyPriceTo) : pr.applyPriceTo,
            }))
        })), null)
    });

export const loadMembershipTypesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        mt.MembershipActionTypes.RequestMembershipTypes,
        action => loadMembershipTypes(),
        err => actionCreators.loadMembershipTypesComplete([], err));

export const reloadMembershipTypesEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        mt.MembershipActionTypes.MembershipTypeSaved,
        action => loadMembershipTypes(),
        err => actionCreators.loadMembershipTypesComplete([], err));


export const saveMembershipTypeEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        mt.MembershipActionTypes.SaveMembershipType,
        action => {
            const sa = action as mt.SaveMembershipType;

            const productImg = sa.productImg;
            const membershipImg = sa.membershipImg;
            const webShopImg = sa.webShopImg;

            const imageObservables: Observable<string>[] = [
                productImg ? api.uploadFile(productImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null),
                membershipImg ? api.uploadFile(membershipImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null),
                webShopImg ? api.uploadFile(webShopImg, 'api/v1/clientimage').map(res => res.response.imageId) : Observable.of(null),
            ];

            const images = Observable.forkJoin(imageObservables);
            return images.flatMap(imgIds => {
                var bodyWithImg = {
                    productId: sa.isNew ? null : sa.membershipType.productId,
                    name: sa.membershipType.name,
                    description: sa.membershipType.description,
                    taxRateId: sa.membershipType.taxRateId,
                    accountingDepartment: sa.membershipType.accountingDepartment,
                    accountingCategory: sa.membershipType.accountingCategory,
                    nominalCode: sa.membershipType.nominalCode,
                    numberCustomPrefix: sa.membershipType.numberCustomPrefix,
                    numberOfDigitsToPad: sa.membershipType.numberOfDigitsToPad,
                    expiryType: sa.membershipType.expiryType,
                    expiryDate: sa.membershipType.expiryDate,
                    expiryInterval: sa.membershipType.expiryInterval,
                    tagId: sa.membershipType.tag.tagId,
                    tagName: sa.membershipType.tag.tagName,
                    tagColour: sa.membershipType.tag.tagColour,
                    promotionIds: sa.membershipType.promotionIds,
                    venueIds: sa.membershipType.venueIds,
                    venuePurchaseSettings: sa.membershipType.venuePurchaseSettings,
                    archived: sa.membershipType.archived,
                    clientEmailTemplateId: sa.membershipType.clientEmailTemplateId,
                    productImageId: imgIds[0],
                    membershipImageId: imgIds[1],
                    webShopImageId: imgIds[2],
                    productInformation: sa.membershipType.productInformation,
                    pricing: sa.membershipType.pricing.map(pr => ({
                        ...pr,
                        effectiveFrom: pr.effectiveFrom.toApiDateOnlyString(),
                        effectiveTo: pr.effectiveTo ? pr.effectiveTo.toApiDateOnlyString() : null,
                        applyPriceFrom: pr.applyPriceFrom ? pr.applyPriceFrom.toString() : null,
                        applyPriceTo: pr.applyPriceTo ? pr.applyPriceTo.toString() : null
                    })),
                    tags: sa.membershipType.tags
                };

                return (sa.isNew ? api.post('api/v1/membership/membershipType/', bodyWithImg) : api.put(`api/v1/membership/membershipType/${sa.membershipType.id}`, { ...bodyWithImg, productId: sa.membershipType.productId }))
                    .map(response => {

                        let membershipTypeId = sa.membershipType.id;
                        if (sa.isNew) {
                            const svr = response.response as ISaveMembershipTypeResponse;
                            if (svr) {
                                membershipTypeId = svr.membershipTypeId;
                            }
                        }

                        return ({ type: mt.MembershipActionTypes.MembershipTypeSaved, membershipTypeId: membershipTypeId });
                    });
            })
        },
        (err: api.ApiError) => actionCreators.membershipTypeSaveFailed(err));


const searchMemberships = (pageNumber: number, pageSize: number, membershipTypeId: string, search: string) => {
    counter = counter + 1;
    const lastCounter = counter;

    const query = `api/v1/membership/${membershipTypeId}/search?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`;

    return Observable.defer(() => api.getJson<ISearchMembershipsResponse>(query))
        .filter(resp => {
            return lastCounter === counter
        })
        .map(response => {
            const mappedMemberships = response.memberships.map(m => ({
                ...m,
                purchaseDate: parseLocalDateTime(m.purchaseDate),
                expiryDate: mapLocalDateTime(m.expiryDate),
            }));
            return actionCreators.membershipsReceived(mappedMemberships, response.hasMorePages ? response.page + 1 : response.page, null)
        });
}

export const searchMembershipsEpic = (action$: ActionsObservable<any>) =>
    epic.create(action$,
        mt.MembershipActionTypes.SearchMemberships,
        action => searchMemberships(action.pageNumber, action.pageSize, action.membershipTypeId, action.search),
        err => actionCreators.membershipsReceived([], 0, err));


