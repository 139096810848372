
import * as React from 'react';
import * as PropTypes from 'prop-types'
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from '../../../store';

import * as DiaryActions from '../../../store/pages/diary/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { Resource } from '../../../store/pages/resources/types';
import { Time } from '../../../store/global/types';
import { OpeningTimes } from '../../../store/pages/diary/types';
import { DateFormat, getOpeningTimes, TimeFormat, Venue } from '../../../store/pages/venues/types';

interface EditOpeningTimesState {
    venueOpeningTime: Time,
    venueClosingTime: Time,
    closed: ct.FormValue<boolean>;
    open: ct.FormValue<Time>;
    close: ct.FormValue<Time>;
    message: ct.FormValue<string>;
    resourceOpeningTimes: ResourceOpeningTimesState[];
    error: string | null;
    dateFormat: DateFormat;
    timeFormat: TimeFormat;
}

interface ResourceOpeningTimesState {
    resourceId: string;
    resourceName: string;
    colour: string;
    closed: ct.FormValue<boolean>;
    open: ct.FormValue<Time>;
    close: ct.FormValue<Time>;
    message: ct.FormValue<string>;
}

interface LocalProps {
    selectedVenueId: string;
    date: moment.Moment;
}

interface MappedReduxState {
    venues: Venue[];
    openingTimes: OpeningTimes[];
    resources: Resource[];
    isSavingOpeningTimes: boolean;
    openingTimesSaveError: api.ApiError | null;
}

interface Actions {
    saveOpeningTimes: (venueId: string, date: Date, openingTimes: OpeningTimes) => void;
    closeModal: () => void;
}

type EditOpeningTimesProps = MappedReduxState & Actions & LocalProps;

class EditOpeningTimes extends React.Component<EditOpeningTimesProps, EditOpeningTimesState> {

    constructor(props: EditOpeningTimesProps) {
        super(props);

        const dateAsDate = props.date.toDate();

        const venue = props.venues.find(v => v.id === props.selectedVenueId);
        const openingTimes = props.openingTimes.find(t => t.date.getFullYear() === props.date.year() && t.date.getMonth() === props.date.month() && t.date.getDate() === props.date.date())

        // Passing empty opening times as we need to get the base venue hours
        const { open, close, closedAllDay } = venue ? getOpeningTimes(venue, null, [], dateAsDate) : { open: null, close: null, closedAllDay: true};
        const venueOpeningTime = open ? open : Time.zero();
        const venueClosingTime = close ? close : Time.zero();

        const closed = openingTimes ? openingTimes.closed : venueOpeningTime.isZero() && venueClosingTime.isZero();

        const resOpeningTimes = props.resources.filter(r => !r.archived && r.venueId === props.selectedVenueId).sort((r1,r2) => r1.sequence - r2.sequence).map(r => {
            var ot = openingTimes ? openingTimes.resourceOverrides.find(x => x.resourceId === r.id) : null;
            return {
                resourceId: r.id,
                resourceName: r.name,
                colour: r.colour,
                closed: this.validateResourceClosed(r.id, ot ? ot.closed : openingTimes ? openingTimes.closed : closed),
                open: this.validateResourceOpeningTime(r.id, ot ? ot.open : openingTimes ? openingTimes.open : venueOpeningTime),
                close: this.validateResourceClosingTime(r.id, ot ? ot.close : openingTimes ? openingTimes.close : venueClosingTime),
                message: this.validateResourceMessage(r.id, ot ? ot.message : ''),
            }
        });

        this.state = {
            venueOpeningTime: venueOpeningTime,
            venueClosingTime: venueClosingTime,
            closed: this.validateClosed(closed),
            open: this.validateOpeningTime(openingTimes ? openingTimes.open : venueOpeningTime),
            close: this.validateClosingTime(openingTimes ? openingTimes.close : venueClosingTime),
            message: this.validateMessage(openingTimes ? openingTimes.message : ''),
            resourceOpeningTimes: resOpeningTimes,
            error: null,
            dateFormat: venue ? venue.dateFormat : DateFormat.DMY,
            timeFormat: venue ? venue.timeFormat : TimeFormat.TwentyFourHour
        };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    validateClosed = (val: boolean) => v.validate(val, 'closed', [], []);
    validateOpeningTime = (val: Time) => v.validate(val, 'open', [], []);
    validateClosingTime = (val: Time) => v.validate(val, 'close', [], []);
    validateMessage = (val: string) => v.validate(val, 'message', [], []);

    validateResourceClosed = (resourceId: string, val: boolean) => v.validate(val, `${resourceId}_closed`, [], []);
    validateResourceOpeningTime = (resourceId: string, val: Time) => v.validate(val, `${resourceId}_open`, [], []);
    validateResourceClosingTime = (resourceId: string, val: Time) => v.validate(val, `${resourceId}_close`, [], []);
    validateResourceMessage = (resourceId: string, val: string) => v.validate(val, `${resourceId}_message`, [], []);

    onClosedChanged = (val: boolean) => this.setState(s => ({ closed: this.validateClosed(val), resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.closed.value === s.closed.value ? { ...r, closed: this.validateResourceClosed(r.resourceId, val) } : r) }))
    onOpeningTimeChanged = (val: Time | null) => this.setState(s => ({ open: this.validateOpeningTime(val ? val : Time.zero()), resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.open.value.val() === s.open.value.val() ? { ...r, open: this.validateResourceOpeningTime(r.resourceId, val ? val : Time.zero()) } : r) }))
    onClosingTimeChanged = (val: Time | null) => this.setState(s => ({ close: this.validateClosingTime(val ? val : Time.zero()), resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.close.value.val() === s.close.value.val() ? { ...r, close: this.validateResourceClosingTime(r.resourceId, val ? val : Time.zero()) } : r) }))

    onResourceClosedChanged = (resourceId: string, val: boolean) => this.setState(s => ({ resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.resourceId === resourceId ? {...r, closed: this.validateResourceClosed(resourceId, val)} : r) }))
    onResourceOpenChanged = (resourceId: string, val: Time | null) => this.setState(s => ({ resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.resourceId === resourceId ? { ...r, open: this.validateResourceOpeningTime(resourceId, val ? val : Time.zero()) } : r) }))
    onResourceCloseChanged = (resourceId: string, val: Time | null) => this.setState(s => ({ resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.resourceId === resourceId ? { ...r, close: this.validateResourceClosingTime(resourceId, val ? val : Time.zero()) } : r) }))
    onResourceMessageChanged = (resourceId: string, val: string) => this.setState(s => ({ resourceOpeningTimes: s.resourceOpeningTimes.map(r => r.resourceId === resourceId ? { ...r, message: this.validateResourceMessage(resourceId, val) } : r) }))

    saveTimes = (times: OpeningTimes) => {
        const { saveOpeningTimes, selectedVenueId, date } = this.props;

        saveOpeningTimes(selectedVenueId, date.toDate(), times);
    }

    save = () => {
        const { date } = this.props;
        const { closed, open, close, message, resourceOpeningTimes } = this.state;

        if (!v.isValid(this.state)) {
            this.setState({ error: 'Global:formNotValid' })
        } else {
            const times: OpeningTimes = {
                date: date.toDate(),
                closed: closed.value,
                open: open.value,
                close: close.value,
                message: message.value,
                resourceOverrides: resourceOpeningTimes
                    .filter(t => !closed.value && (t.closed.value !== closed.value || t.open.value !== open.value || t.close.value !== close.value || t.message.value !== message.value))
                    .map(t => ({ resourceId: t.resourceId, closed: t.closed.value, open: t.open.value, close: t.close.value, message: t.message.value }))
            }

            this.saveTimes(times);
            this.setState({ error: null });
        }
    }

    componentDidUpdate(prevProps: EditOpeningTimesProps) {
        const { isSavingOpeningTimes: prevIsSavingOpeningTimes } = prevProps;
        const { isSavingOpeningTimes, openingTimesSaveError, closeModal } = this.props;

        // Close modal overlay if diary note save is complete
        if (prevIsSavingOpeningTimes && !isSavingOpeningTimes && !openingTimesSaveError) {
            closeModal();
        }
    }

    render() {
        const { date, closeModal, isSavingOpeningTimes, openingTimesSaveError } = this.props;
        const { venueOpeningTime, venueClosingTime, closed, open, close, message, resourceOpeningTimes, error, dateFormat, timeFormat } = this.state;
        const { t } = this.context;

        const errorMessage = error
            ? <div className='alert alert-danger'>{t(error)}</div>
            : openingTimesSaveError ? <ApiError error={openingTimesSaveError} /> : null;

        return (
            <div>
                <h3>{t('EditOpeningTimes:heading', { date: date.toDate().toAbbrDateString(dateFormat, t) })}</h3>

                <div className='edit-opening-times-standard-hours'>
                    {t('EditOpeningTimes:standardHours')}
                    {venueOpeningTime.isZero() && venueClosingTime.isZero() ? <span>:  {t('Global:closed')}</span> : <span>  {venueOpeningTime.toShortTimeString()} - {venueClosingTime.toShortTimeString()}</span>}
                </div>


                <div className='row mt-15'>
                    <div className='col-xs-4'>
                        <ct.Checkbox id='closed' labelKey='Global:closed' value={closed} callback={this.onClosedChanged} />
                    </div>
                    <div className='col-xs-4'>
                        <ct.Time id='openTime' labelKey='EditOpeningTimes:open' value={open} timeFormat={timeFormat} callback={this.onOpeningTimeChanged} disabled={closed.value} />
                    </div>
                    <div className='col-xs-4'>
                        <ct.Time id='closeTime' labelKey='EditOpeningTimes:close' value={close} timeFormat={timeFormat} callback={this.onClosingTimeChanged} disabled={closed.value} />
                    </div>
                </div>

                <ct.TextArea id='comment' labelKey='EditOpeningTimes:message' placeholderKey='EditOpeningTimes:message' rows={5} value={message} callback={val => this.setState({ message: this.validateMessage(val) })} />

                <table className='table table-condensed'>
                    <thead>
                        <tr>
                            <th>{t('EditOpeningTimes:resourceHeading')}</th>
                            <th>{t('Global:closed')}</th>
                            <th>{t('EditOpeningTimes:open')}</th>
                            <th>{t('EditOpeningTimes:close')}</th>
                            <th>{t('EditOpeningTimes:message')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resourceOpeningTimes.map(r => <tr key={r.resourceId}>
                            <td><div style={{ backgroundColor: r.colour, color: '#fff', padding: '4px 6px'}}>{r.resourceName}</div></td>
                            <td><ct.Checkbox id={`${r.resourceId}_closed`} labelKey='' value={r.closed} callback={val => this.onResourceClosedChanged(r.resourceId, val)} disabled={closed.value} /></td>
                            <td><ct.Time id={`${r.resourceId}_openTime`} labelKey='' value={r.open} timeFormat={timeFormat} callback={val => this.onResourceOpenChanged(r.resourceId, val)} disabled={closed.value || r.closed.value} /></td>
                            <td><ct.Time id={`${r.resourceId}_closeTime`} labelKey='' value={r.close} timeFormat={timeFormat} callback={val => this.onResourceCloseChanged(r.resourceId, val)} disabled={closed.value || r.closed.value} /></td>
                            <td><ct.TextBox id={`${r.resourceId}_message`} labelKey='' value={r.message} callback={val => this.onResourceMessageChanged(r.resourceId, val)} disabled={closed.value} /></td>
                        </tr>)}
                    </tbody>
                </table>

                {errorMessage}

                <div className='btn-toolbar' style={({ marginTop: '20px' })}>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)} disabled={isSavingOpeningTimes}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, closeModal)} disabled={isSavingOpeningTimes}>{t('Global:cancel')}</button>
                </div>
            </div>
        );
    }
}


const matStateToProps = (state: ApplicationState) => ({
    venues: state.venues.venues,
    openingTimes: state.diary.openingTimes,
    resources: state.resources.resources,
    isSavingOpeningTimes: state.diary.isSavingOpeningTimes,
    openingTimesSaveError: state.diary.openingTimesSaveError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveOpeningTimes: bindActionCreators(DiaryActions.actionCreators.saveOpeningTimes, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    matStateToProps,
    mapDispatchToProps
)(EditOpeningTimes);
