

import * as React from 'react';
import { Time } from '../../../store/global/types';
import { DiaryReservation, OpeningTimes } from '../../../store/pages/diary/types';
import { findBreaksForDay, Resource } from '../../../store/pages/resources/types';
import { Venue } from '../../../store/pages/venues/types';
import { formatTime } from '../../../utils/util';

import DiaryResource from './diaryResource';
import DiaryResourceHeader from './daryResourceHeader';


interface InlineCalendarResourceProps {
    slotSizeInMinutes: number;
    slotHeight: number;
    date: Date;
    closed: boolean;
    openTime: Time | null;
    closeTime: Time | null;
    scrollToTime?: Time;
    venue: Venue;
    resource: Resource;
    width: number;
    reservations: DiaryReservation[];
    createNewReservation: (resource: Resource, startTime: Date, endTime: Date) => void;
    onReservationSelected: (reservationId: string, eventId: string) => void;
}

class InlineCalendarResource extends React.Component<InlineCalendarResourceProps> {

    scrollDiv: HTMLDivElement | null;

    constructor(props: InlineCalendarResourceProps) {
        super(props);

        this.scrollDiv = null;
    }

    componentDidMount() {
        const { scrollToTime } = this.props;

        if (scrollToTime && this.scrollDiv) {
            const top = this.timeToPixel(scrollToTime);
            this.scrollDiv.scrollTop = top;
        }
    }

    timeToPixel = (time: Time) => {
        const { slotSizeInMinutes, slotHeight, openTime } = this.props;
        const pixelsPerMinute = slotHeight / slotSizeInMinutes;

        const startTime = openTime ? openTime.getHours() : 0;
        const slotTop = ((time.getMinutes() + (time.getHours() * 60)) * pixelsPerMinute);
        const top = slotTop - (startTime * 60 * pixelsPerMinute);
        return top;
    }

    render() {
        const { date, closed, openTime, closeTime, width, slotSizeInMinutes, slotHeight, scrollToTime, reservations,
            resource, createNewReservation, onReservationSelected, venue } = this.props;

        const startTime = openTime ? Math.max(0, openTime.getHours() - 2) : 0;
        const endTime = closeTime ? Math.min(24, closeTime.getHours() + 2) : 0;

        interface time { val: string; hour: boolean };
        const times: time[] = [];
        for (let h = startTime; h < endTime; h++) {
            for (let m = 0; m < 60; m += slotSizeInMinutes) {
                times.push({ val: formatTime(h, m, venue.timeFormat), hour: m === 0 });
            }
        }

        const slots = times.length;
        const lineHeightStyle = { lineHeight: `${slotHeight}px` };
        const eventStyle = { ...lineHeightStyle, height: `${slots * slotHeight}px` };
        const timeItems = times.slice(1).map(t => <div key={t.val} className={t.hour ? 'cal-hour cal-time' : 'cal-time'}>{t.val}</div>);
        const resourceOpeningTimes = { closed: closed, open: openTime || Time.zero(), close: closeTime || Time.zero() };

        return <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <DiaryResourceHeader key='cal-header' colour={resource.colour} name={resource.name} width={width} opeiningTimes={resourceOpeningTimes} timeFormat={venue.timeFormat} />
            <div className='inline-calendar-wrapper' ref={(div: HTMLDivElement) => { this.scrollDiv = div; }}>
                <div className='flex flex-row' style={eventStyle}>
                    <div className='cal-times' style={({ flex: '0 0 auto' })}>{timeItems}</div>
                    <div style={({ flex: '1 1 auto' })}>
                        <DiaryResource
                            venue={venue}
                            addReservation={createNewReservation}
                            date={date}
                            preSelectTime={scrollToTime}
                            startTime={startTime}
                            endTime={endTime}
                            reservations={reservations.sort((r1, r2) => r1.startTime.getTime() - r2.startTime.getTime())}
                            resource={resource}
                            breaks={findBreaksForDay(resource, date)}
                            slotHeight={slotHeight}
                            slotSizeInMinutes={slotSizeInMinutes}
                            editReservation={onReservationSelected}
                            width={width}
                        />
                    </div>
                </div>
            </div>
        </div>
    }
}

export default InlineCalendarResource;