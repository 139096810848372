
import { Interval, ValidationError } from '../../global/types';
import * as api from '../../apiClient';
import { ProductPrice, ProductTag } from '../products/types';

export enum VoucherProductRestrictionType {
    None = 0,
    Categories = 1,
    Tags = 2,
    IndividualSelections = 3
}

export interface VoucherProduct {
    id: string;
    productId: string;
    name: string;
    description: string;
    pricing: ProductPrice[];
    taxRateId: string;
    taxRate: number;
    taxRateName: string;
    accountingDepartment: string;
    accountingCategory: string;
    nominalCode: string;
    productImageUrl: string;
    redemptionAmount: number;
    voucherValidFor: Interval;
    showOnWebShop: boolean;
    canRedeemOnline: boolean;
    allowCustomPrice: boolean;
    minPrice: number;
    maxPrice: number;
    priceIncrement: number;
    maxQuantity: number;
    activeFromDate: Date | null;
    tags: ProductTag[];
    productInformation: string | null;
    archived: boolean;
    voucherImageUrl: string;
    webShopImageUrl: string;
    clientEmailTemplateId: string;
    venueSettings: VoucherVenueSettings[];
    version: number;
    mustBeRedeemedBySingleCustomer: boolean;
    productRestrictionType: VoucherProductRestrictionType;
    restrictionIds: string[];
}

export interface VoucherVenueSettings {
    venueId: string;
    publicWebsiteIdsToSellOn: number[];
    sellOnPointOfSale: boolean;
}

export interface VoucherState {
    isActive: boolean;
    isLoading: boolean;
    voucherProducts: VoucherProduct[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    voucherSearchProductId: string | null;
    voucherSearch: string;
    searchPageNumber: number;
    searchPageSize: number;
    searchMaxPage: number;
    isSearching: boolean;
    searchError: api.ApiError | null;
    vouchers: Voucher[];
}

export interface Voucher {
    voucherId: string;
    voucherCode: string;
    voucherProductName: string;
    purchaseDate: Date;
    expiryDate: Date | null;
    activeFromDate: Date | null;
    hasExpired: boolean;
    isActive: boolean;
    revoked: boolean;
    billId: string;
    billNumber: string;
    redemptionAmount: number;
    remainingBalance: number;
    customerEmail: string;
    customerFirstname: string;
    customerLastname: string;
    lastSentToEmail: string | null;
    paid: boolean;
    createdBy: string;
    emailLastSent: Date | null;
    mustBeRedeemedBySingleCustomer: boolean;
    productRestrictionType: VoucherProductRestrictionType;
    restrictionIds: string[];
}

export enum VoucherActionTypes {
    RequestVoucherProducts = 'REQUEST_VOUCHER_PRODUCTS',
    ReceivedVoucherProducts = 'RECEIVE_VOUCHER_PRODUCTS',
    SaveVoucherProduct = 'SAVE_VOUCHER_PRODUCT',
    VoucherProductSaved = 'VOUCHER_PRODUCT_SAVED',
    VoucherProductSaveFailed = 'VOUCHER_PRODUCT_SAVE_FAILED',
    EditVoucherProduct = 'EDIT_VOUCHER_PRODUCT',
    SearchVouchers = "SEARCH_VOUCHERS",
    VouchersReceived = "VOUCHERS_RECEIVED",
}

export interface LoadVoucherProducts {
    type: VoucherActionTypes.RequestVoucherProducts;
}

export interface ReceiveVoucherProducts {
    type: VoucherActionTypes.ReceivedVoucherProducts;
    voucherProducts: VoucherProduct[];
    error: api.ApiError | null;
}

export interface SaveVoucherProduct {
    type: VoucherActionTypes.SaveVoucherProduct;
    isNew: boolean;
    voucherProduct: VoucherProduct;
    productImg: File | null;
    voucherImg: File | null;
    webShopImage: File | null;
}

export interface VoucherProductSaved {
    type: VoucherActionTypes.VoucherProductSaved;
    voucherProductId: string;
}

export interface VoucherProductSaveFailed {
    type: VoucherActionTypes.VoucherProductSaveFailed;
    error: api.ApiError;
}

export interface EditVoucherProduct {
    type: VoucherActionTypes.EditVoucherProduct
}

export interface SearchVouchers {
    type: VoucherActionTypes.SearchVouchers,
    voucherProductId: string | null;
    search: string;
    pageNumber: number;
    pageSize: number;
}

export interface VouchersReceived {
    type: VoucherActionTypes.VouchersReceived,
    searchError: api.ApiError | null;
    vouchers: Voucher[];
    maxPage: number;
}

