
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'
import Dropzone from 'react-dropzone';
import { ImageFile } from '../../../utils/images';
import { ApplicationState } from '../../../store';
import { ClientToken, ClientTokenType, IClient } from '../../../store/pages/clients/types';
import * as api from '../../../store/apiClient';
import * as ct from '../../global/controls';
import * as v from '../../global/validation';
import * as ClientActions from '../../../store/pages/clients/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import * as TimeZoneActions from '../../../store/global/timeZones/actions';
import { getClientImageThunbUrl } from '../../../store/apiClient';
import {allCountries, ukCountryId} from '../../../store/global/countries';
import { clickHandler } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { ValidationError } from '../../../store/global/types';
import { PaymentGateway } from '../../../store/pages/paymentGateways/types';
import { TimeZone } from '../../../store/global/timeZones/types';

interface LocalProps {
    isNew: boolean;
    client: IClient | null;
}

interface MappedReduxState {
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
    paymentGateways: PaymentGateway[];
    timeZones: TimeZone[];
    isLoadingTimeZones: boolean;
}

interface Actions { //{P }= typeof ClientActions.actionCreators & typeof ModalActions.actionCreators
    saveClient: (isNew: boolean, clientId: number | null, client: IClient, logoImg: File | null) => void;
    loadTimeZones: () => void;
    closeModal: () => void;
}

type ClientFormProps = MappedReduxState & Actions & LocalProps;

interface IClientFormState {
    name: ct.FormValue<string>;
    registeredName: ct.FormValue<string>;
    companyNumber: ct.FormValue<string>;
    chargeSalesTax: ct.FormValue<boolean>;
    salesTaxNumber: ct.FormValue<string>;
    contactFirstName: ct.FormValue<string>;
    contactLastName: ct.FormValue<string>;
    contactEmailAddress: ct.FormValue<string>;
    sendEmailsFromAddress: ct.FormValue<string>;
    sendEmailsFromName: ct.FormValue<string>;
    billingAddressLine1: ct.FormValue<string>;
    billingAddressLine2: ct.FormValue<string>;
    billingAddressLine3: ct.FormValue<string>;
    billingAddressLine4: ct.FormValue<string>;
    billingTown: ct.FormValue<string>;
    billingCounty: ct.FormValue<string>;
    billingCountryId: ct.FormValue<number>;
    billingPostalCode: ct.FormValue<string>;
    billingEmailAddress: ct.FormValue<string>;
    phoneNumber: ct.FormValue<string>;
    websiteAddress: ct.FormValue<string>;
    enableMultipleCategoriesPerCustomer: ct.FormValue<boolean>;
    gatewayFees: PaymentGatewayFeeState[];
    tokens: ClientToken[];
    archived: ct.FormValue<boolean>;
    timeZoneId: ct.FormValue<number>;
    logoImageId: string | null;
    logoImage: ImageFile | null;
}

interface PaymentGatewayFeeState {
    paymentGatewayId: number;
    paymentGatewayName: string;
    fee: ct.FormValue<number | null>;
}

class ClientForm extends React.Component<ClientFormProps, IClientFormState> {

    constructor(props: ClientFormProps) {
        super(props);

        this.state = this.buildStateFromProps(this.props);
    }

    static contextTypes = {
        t: PropTypes.func
    }

    private buildStateFromProps(props: ClientFormProps): IClientFormState {

        const { isNew, client, paymentGateways } = props;

        const gatewayFees = paymentGateways.map(g => {
            const clientFee = client && client.paymentGatewayFees ? client.paymentGatewayFees.filter(f => f.paymentGatewayId === g.id).map(f => f.feePercentage)[0] : null;
            return { paymentGatewayId: g.id, paymentGatewayName: g.name, fee: ct.asFormValue(`gatewayFee_${g.id}`, clientFee ? clientFee : null) }
        });

        return {
            name: this.validateName(isNew || !client ? '' : client.name),
            registeredName: this.validateRegisteredName(isNew || !client ? '' : client.registeredName),
            companyNumber: this.validateCompanyNumber(isNew || !client ? '' : client.companyNumber),
            chargeSalesTax: this.validateChargeSalesTax(isNew || !client ? false : client.chargeSalesTax),
            salesTaxNumber: this.validateTaxNumber(isNew || !client ? '' : client.salesTaxNumber),
            contactFirstName: this.validateContactFirstName(isNew || !client ? '' : client.contactFirstName),
            contactLastName: this.validateContactLastName(isNew || !client ? '' : client.contactLastName),
            contactEmailAddress: this.validateContactEmailAddress(isNew || !client ? '' : client.contactEmailAddress),
            sendEmailsFromAddress: this.validateSendEmailsFromAddress(isNew || !client ? '' : client.sendEmailsFromAddress),
            sendEmailsFromName: this.validateSendEmailsFromName(isNew || !client ? '' : client.sendEmailsFromName),
            billingAddressLine1: this.validateBillingAddressLine1(isNew || !client ? '' : client.billingAddressLine1),
            billingAddressLine2: this.validateBillingAddressLine2(isNew || !client ? '' : client.billingAddressLine2),
            billingAddressLine3: this.validateBillingAddressLine3(isNew || !client ? '' : client.billingAddressLine3),
            billingAddressLine4: this.validateBillingAddressLine4(isNew || !client ? '' : client.billingAddressLine4),
            billingTown: this.validateBillingTown(isNew || !client ? '' : client.billingTown),
            billingCounty: this.validateBillingCounty(isNew || !client ? '' : client.billingCounty),
            billingCountryId: this.validateBillingCountry(isNew || !client ? ukCountryId : client.billingCountryId),
            billingPostalCode: this.validateBillingPostalCode(isNew || !client ? '' : client.billingPostalCode),
            billingEmailAddress: this.validateBillingEmailAddress(isNew || !client ? '' : client.billingEmailAddress),
            phoneNumber: this.validatePhoneNumber(isNew || !client ? '' : client.phoneNumber),
            websiteAddress: this.validateWebsiteAddress(isNew || !client ? '' : client.websiteAddress),
            enableMultipleCategoriesPerCustomer: this.validateEnableMultipleCategoriesPerCustomer(isNew || !client ? false : client.enableMultipleCategoriesPerCustomer),
            gatewayFees: gatewayFees,
            tokens: isNew || !client ? [] : client.tokens,
            timeZoneId: this.validateTimeZone(isNew || !client ? 39 : client.timeZoneId),
            archived: this.validateArchived(isNew || !client ? false : client.archived),
            logoImageId: isNew || !client ? null : client.logoImageId,
            logoImage: null
        };
    }

    componentDidMount() {
        const { timeZones, isLoadingTimeZones, loadTimeZones } = this.props;
        if ((!timeZones || timeZones.length === 0) && !isLoadingTimeZones) {
            loadTimeZones();
        }
    }

    componentDidUpdate(prevProps: ClientFormProps) {
        const { client, isNew, saveComplete } = this.props;
        // Only update state is client has changed
        if ((!prevProps.client && client) ||
            (prevProps.client && !client) ||
            (isNew && !prevProps.isNew) ||
            (prevProps.client && client && prevProps.client.id !== client.id)) {
            this.setState(this.buildStateFromProps(this.props));
        }

        if (saveComplete && !prevProps.saveComplete) {
            setTimeout(() => { this.close(); }, 750);
        }
    }

    private saveClient = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    }

    private close = () => {
        this.props.closeModal();
    }

    private save = () => {

        if (!v.isValid(this.state)) {
            // TODO: Show error message!
        } else {

            const clientId = this.props.isNew || !this.props.client ? null : this.props.client.id;
            const gatewayFees = this.state.gatewayFees.filter(f => f.fee.value).map(f => ({ paymentGatewayId: f.paymentGatewayId, feePercentage: f.fee.value || 0 }))
            this.props.saveClient(this.props.isNew,
                clientId,
                {
                    id: (clientId || 0),
                    name: this.state.name.value,
                    registeredName: this.state.registeredName.value,
                    companyNumber: this.state.companyNumber.value,
                    contactFirstName: this.state.contactFirstName.value,
                    contactLastName: this.state.contactLastName.value,
                    contactEmailAddress: this.state.contactEmailAddress.value,
                    sendEmailsFromAddress: this.state.sendEmailsFromAddress.value,
                    sendEmailsFromName: this.state.sendEmailsFromName.value,
                    chargeSalesTax: this.state.chargeSalesTax.value,
                    salesTaxNumber: this.state.salesTaxNumber.value,
                    billingAddressLine1: this.state.billingAddressLine1.value,
                    billingAddressLine2: this.state.billingAddressLine2.value,
                    billingAddressLine3: this.state.billingAddressLine3.value,
                    billingAddressLine4: this.state.billingAddressLine4.value,
                    billingTown: this.state.billingTown.value,
                    billingCounty: this.state.billingCounty.value,
                    billingCountryId: this.state.billingCountryId.value,
                    billingPostalCode: this.state.billingPostalCode.value,
                    billingEmailAddress: this.state.billingEmailAddress.value,
                    phoneNumber: this.state.phoneNumber.value,
                    websiteAddress: this.state.websiteAddress.value,
                    enableMultipleCategoriesPerCustomer: this.state.enableMultipleCategoriesPerCustomer.value,
                    paymentGatewayFees: gatewayFees,
                    tokens: this.state.tokens,
                    timeZoneId: this.state.timeZoneId.value,
                    archived: this.state.archived.value,
                    logoImageId: null
                },
                this.state.logoImage ? this.state.logoImage.file : null
            );
        }
    }

    onImageDrop = (files: File[]) => {
        const file = files[0];
        this.setState({ logoImage: { file: file, preview: URL.createObjectURL(file) } });
    }

    //onNameChanged = (name: string) => this.setState({ name: this.validateName(name) });

    validateName = (val: string) => v.validate(val, 'name', [v.required], this.props.validationErrors);
    validateRegisteredName = (val: string) => v.validate(val, 'registeredName', [v.required], this.props.validationErrors);
    validateCompanyNumber = (val: string) => v.validate(val, 'companyNumber', [v.required], this.props.validationErrors);
    validateChargeSalesTax = (val: boolean) => v.validate(val, 'chargeSalesTax', [], this.props.validationErrors);
    validateTaxNumber = (val: string) => v.validate(val, 'taxNumber', [], this.props.validationErrors);
    validateContactFirstName = (val: string) => v.validate(val, 'contactFirstName', [v.required], this.props.validationErrors);
    validateContactLastName = (val: string) => v.validate(val, 'contactLastName', [v.required], this.props.validationErrors);
    validateContactEmailAddress = (val: string) => v.validate(val, 'contactEmailAddress', [v.required], this.props.validationErrors);
    validateSendEmailsFromAddress = (val: string) => v.validate(val, 'sendEmailsFromAddress', [v.required], this.props.validationErrors);
    validateSendEmailsFromName = (val: string) => v.validate(val, 'sendEmailsFromName', [], this.props.validationErrors);
    validateBillingEmailAddress = (val: string) => v.validate(val, 'billingEmailAddress', [v.required], this.props.validationErrors);
    validateBillingAddressLine1 = (val: string) => v.validate(val, 'billingAddressLine1', [v.required], this.props.validationErrors);
    validateBillingAddressLine2 = (val: string) => v.validate(val, 'billingAddressLine2', [], this.props.validationErrors);
    validateBillingAddressLine3 = (val: string) => v.validate(val, 'billingAddressLine3', [], this.props.validationErrors);
    validateBillingAddressLine4 = (val: string) => v.validate(val, 'billingAddressLine4', [], this.props.validationErrors);
    validateBillingTown = (val: string) => v.validate(val, 'billingTown', [v.required], this.props.validationErrors);
    validateBillingCounty = (val: string) => v.validate(val, 'billingCounty', [v.required], this.props.validationErrors);
    validateBillingCountry = (val: number) => v.validate(val, 'billingCountry', [v.numeric], this.props.validationErrors);
    validateBillingPostalCode = (val: string) => v.validate(val, 'billingPostalCode', [v.required], this.props.validationErrors);
    validatePhoneNumber = (val: string) => v.validate(val, 'phoneNumber', [], this.props.validationErrors);
    validateWebsiteAddress = (val: string) => v.validate(val, 'websiteAddress', [], this.props.validationErrors);
    validateEnableMultipleCategoriesPerCustomer = (val: boolean) => v.validate(val, 'enableMultipleCategoriesPerCustomer', [], this.props.validationErrors);
    validateTimeZone = (val: number) => v.validate(val, 'timeZone', [v.numeric, v.required], this.props.validationErrors);
    validateArchived = (val: boolean) => v.validate(val, 'archived', [], this.props.validationErrors);

    updateGatewayFee(paymentGatewayId: number, fee: number | null): void {
        this.setState(s => ({ gatewayFees: s.gatewayFees.map(f => f.paymentGatewayId === paymentGatewayId ? { ...f, fee: ct.asFormValue(`gatewayFee_${f.paymentGatewayId}`, fee)} : f)}))
    }


    render() {

        const { t } = this.context;
        const { client } = this.props;
        const clientId = client ? client.id : null;
        let message: any;

        if (this.props.saveError) {
            message = <ApiError error={this.props.saveError} />;
        } else if (this.props.saveComplete) {
            message = (<div className='bg-success'>{t('Global:saveComplete')}</div>);
        }

        let previewImg: JSX.Element | null = null;
        const imageUrl = this.state.logoImage ?  this.state.logoImage.preview : this.state.logoImageId !== null ? getClientImageThunbUrl(this.state.logoImageId, 200) : null;
        if (imageUrl) {
            previewImg = <img src={imageUrl} className='file-preview' alt='preview'></img>;
        }

        return <div className='clientForm'>
            <h1 className='client_title'>{this.props.isNew ? t('ClientForm:addClient') : this.state.name.value}</h1>

            <form className='data-form' onSubmit={this.saveClient} autoComplete='off'>
                {clientId ? <div><label>{t('ClientForm:clientId')}</label>: {clientId}</div> : null}
                <ct.TextBox id='name' labelKey='Global:name' placeholderKey='Global:name' value={this.state.name} callback={val => this.setState({ name: this.validateName(val) })} />
                <ct.TextBox id='registeredName' labelKey='ClientForm:registeredName' placeholderKey='ClientForm:registeredName' value={this.state.registeredName} callback={val => this.setState({ registeredName: this.validateRegisteredName(val) })} />
                <ct.TextBox id='companyNumber' labelKey='ClientForm:companyNumber' placeholderKey='ClientForm:companyNumber' value={this.state.companyNumber} callback={val => this.setState({ companyNumber: this.validateCompanyNumber(val) })} />
                <ct.Checkbox id='chargeSalesTax' labelKey='ClientForm:chargeSalesTax' value={this.state.chargeSalesTax} callback={val => this.setState({ chargeSalesTax: this.validateChargeSalesTax(val) })} />
                <ct.TextBox id='taxNumber' labelKey='ClientForm:taxNumber' placeholderKey='ClientForm:taxNumber' value={this.state.salesTaxNumber} callback={val => this.setState({ salesTaxNumber: this.validateTaxNumber(val) })} />

                <ct.TextBox id='contactFirstName' labelKey='ClientForm:contactFirstName' placeholderKey='ClientForm:contactFirstName' value={this.state.contactFirstName} callback={val => this.setState({ contactFirstName: this.validateContactFirstName(val) })} />
                <ct.TextBox id='contactLastName' labelKey='ClientForm:contactLastName' placeholderKey='ClientForm:contactLastName' value={this.state.contactLastName} callback={val => this.setState({ contactLastName: this.validateContactLastName(val) })} />
                <ct.Email id='contactEmailAddress' labelKey='ClientForm:contactEmailAddress' placeholderKey='ClientForm:contactEmailAddress' value={this.state.contactEmailAddress} callback={val => this.setState({ contactEmailAddress: this.validateContactEmailAddress(val) })} />
                <ct.Email id='sendEmailsFromAddress' labelKey='Global:sendEmailsFromAddress' placeholderKey='Global:sendEmailsFromAddress' value={this.state.sendEmailsFromAddress} callback={val => this.setState({ sendEmailsFromAddress: this.validateSendEmailsFromAddress(val) })} />
                <ct.Email id='sendEmailsFromName' labelKey='Global:sendEmailsFromName' placeholderKey='Global:sendEmailsFromName' value={this.state.sendEmailsFromName} callback={val => this.setState({ sendEmailsFromName: this.validateSendEmailsFromName(val) })} />

                <ct.Email id='billingEmailAddress' labelKey='ClientForm:billingEmailAddress' placeholderKey='Global:emailAddress' value={this.state.billingEmailAddress} callback={val => this.setState({ billingEmailAddress: this.validateBillingEmailAddress(val) })} />
                <ct.TextBox id='billingAddressLine1' labelKey='ClientForm:billingAddressLine1' placeholderKey='Global:addressLine1' value={this.state.billingAddressLine1} callback={val => this.setState({ billingAddressLine1: this.validateBillingAddressLine1(val) })} />
                <ct.TextBox id='billingAddressLine2' labelKey='ClientForm:billingAddressLine2' placeholderKey='Global:addressLine2' value={this.state.billingAddressLine2} callback={val => this.setState({ billingAddressLine2: this.validateBillingAddressLine2(val) })} />
                <ct.TextBox id='billingAddressLine3' labelKey='ClientForm:billingAddressLine3' placeholderKey='Global:addressLine3' value={this.state.billingAddressLine3} callback={val => this.setState({ billingAddressLine3: this.validateBillingAddressLine3(val) })} />
                <ct.TextBox id='billingAddressLine4' labelKey='ClientForm:billingAddressLine4' placeholderKey='Global:addressLine4' value={this.state.billingAddressLine4} callback={val => this.setState({ billingAddressLine4: this.validateBillingAddressLine4(val) })} />
                <ct.TextBox id='billingTown' labelKey='ClientForm:billingTown' placeholderKey='Global:town' value={this.state.billingTown} callback={val => this.setState({ billingTown: this.validateBillingTown(val) })} />
                <ct.TextBox id='billingCounty' labelKey='ClientForm:billingCounty' placeholderKey='Global:county' value={this.state.billingCounty} callback={val => this.setState({ billingCounty: this.validateBillingCounty(val) })} />
                <ct.Country id='billingCountry' labelKey='ClientForm:billingCountry' placeholderKey='Global:country' value={this.state.billingCountryId} callback={val => this.setState({ billingCountryId: this.validateBillingCountry(val) })} countries={allCountries} />
                <ct.TextBox id='billingPostalCode' labelKey='ClientForm:billingPostalCode' placeholderKey='Global:postalCode' value={this.state.billingPostalCode} callback={val => this.setState({ billingPostalCode: this.validateBillingPostalCode(val) })}  />

                <ct.PhoneNumber id='phoneNumber' labelKey='Global:phoneNumber' placeholderKey='Global:phoneNumber' value={this.state.phoneNumber} callback={val => this.setState({ phoneNumber: this.validatePhoneNumber(val) })} />
                <ct.TextBox id='websiteAddress' labelKey='Global:websiteAddress' placeholderKey='Global:websiteAddress' value={this.state.websiteAddress} callback={val => this.setState({ websiteAddress: this.validateWebsiteAddress(val) })} />

                <ct.Select id='timeZone' labelKey='VenueForm:timeZone' value={({ ...this.state.timeZoneId, value: this.state.timeZoneId.value.toString() })} options={this.props.timeZones.map(z => ({ key: z.id.toString(), name: z.name }))} callback={val => this.setState({ timeZoneId: this.validateTimeZone(parseInt(val)) })} />

                <ct.Checkbox id='enableMultipleCategoriesPerCustomer' labelKey='ClientForm:enableMultipleCategoriesPerCustomer' value={this.state.enableMultipleCategoriesPerCustomer} callback={val => this.setState({ enableMultipleCategoriesPerCustomer: this.validateEnableMultipleCategoriesPerCustomer(val) })} />

                <ct.Checkbox id='archived' labelKey='Global:archive' value={this.state.archived} callback={val => this.setState({ archived: this.validateArchived(val) })} />

                <h3>{t('ClientForm:tokens')}</h3>
                <table className='table'>
                    <tbody>
                        {this.state.tokens.map(token => <tr key={`tokenType_${token.type}`}>
                            <td>{t(`ClientTokenType:${ClientTokenType[token.type]}`)}</td>
                            <td>{token.token}</td>
                        </tr>)}
                    </tbody>
                </table>


                <h3>{t('ClientForm:gatewayFees')}</h3>
                <table>
                    <tbody>
                        {this.state.gatewayFees.map(f => <tr key={f.paymentGatewayId}>
                            <td>{f.paymentGatewayName}</td>
                            <td><ct.NumberBox id={`${f.paymentGatewayId}_fee`} labelKey='' value={f.fee} callback={v => this.updateGatewayFee(f.paymentGatewayId, v)} minimal={true} min='0' /></td>
                        </tr>)}
                    </tbody>
                </table>

                <span>{t('ClientForm:logo')}</span>
                <div>
                    <Dropzone multiple={false} accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg' ]}} onDrop={this.onImageDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className='file-drop'>
                                <input {...getInputProps()} />
                                <p>{t('Global:imageDropText')}</p>
                            </div>
                        )}
                    </Dropzone>
                    {previewImg}
                </div>

                {message}

                <p />
                <div className='btn-toolbar'>
                    <button className='btn btn-primary' onClick={e => clickHandler(e, this.save)}>{t('Global:save')}</button>
                    <button className='btn btn-basic' onClick={e => clickHandler(e, this.close)}>{t('Global:cancel')}</button>
                </div>
            </form>
        </div>;
    }
};


const mapStateToProps = (state: ApplicationState) => ({
    isSaving: state.clients.isSaving,
    saveComplete: state.clients.saveComplete,
    saveError: state.clients.saveError,
    validationErrors: state.clients.validationErrors,
    paymentGateways: state.paymentGateways.paymentGateways,
    timeZones: state.timeZones.timeZones,
    isLoadingTimeZones: state.timeZones.isLoading
})

//const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(Object.assign({}, ClientActions.actionCreators, ModalActions.actionCreators), dispatch);
const mapDispatchToProps = (dispatch: Dispatch) => ({
    saveClient: bindActionCreators(ClientActions.actionCreators.saveClient, dispatch),
    loadTimeZones: bindActionCreators(TimeZoneActions.actionCreators.loadTimeZones, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(
    mapStateToProps,           // Selects which state properties are merged into the component's props
    mapDispatchToProps,        // Selects which action creators are merged into the component's props
)(ClientForm);
