
import * as React from 'react';
import * as PropTypes from 'prop-types'

import { AccountCredit } from './customerCreditDetails';
import { clickHandler } from '../../../utils/util';
import { DateFormat, TimeFormat } from '../../../store/pages/venues/types';

interface CustomerCreditRowProps {
    history: AccountCredit;
    timeFormat: TimeFormat;
    dateFormat: DateFormat;
    expire: (history: AccountCredit) => void;
}

interface CustomerCreditRowState {
    confirmExpiry: boolean;
}


class CustomerCreditRow extends React.Component<CustomerCreditRowProps, CustomerCreditRowState> {
    constructor(props: CustomerCreditRowProps) {
        super(props);

        this.state = { confirmExpiry: false };
    }

    static contextTypes = {
        t: PropTypes.func
    }

    render() {
        const { history: h, expire, timeFormat, dateFormat } = this.props;
        const { confirmExpiry } = this.state;
        const { t } = this.context;

        const action = confirmExpiry
            ? <div>
                <button className='btn btn-xs btn-warning' style={{margin: '0px 5px'}} onClick={e => clickHandler(e, () => expire(h))}>{t('customerCreditDetails:confirmExpire')}</button>
                <button className='btn btn-xs btn-default' onClick={e => clickHandler(e, () => this.setState({ confirmExpiry: false }))}>{t('Global:cancel')}</button>
            </div>
            : h.creditAmount && !h.void ? <button className='btn btn-link' style={{ padding: '0', margin: '0' }} onClick={e => clickHandler(e, () => this.setState({ confirmExpiry: true }))}>{t('customerCreditDetails:expire')}</button> : null

        return <tr key={h.id}>
            <td className='shrink text-right'>{h.createDateTime.toAbbrDateTimeString(timeFormat, dateFormat, t)}</td>
            <td className='shrink text-right'>{h.creditAmount ? `${t('Global:currencySymbol')}${h.creditAmount.toFixed(2)}` : ''}</td>
            <td className='shrink text-right'>{h.debitAmount ? `${t('Global:currencySymbol')}${(h.debitAmount * -1).toFixed(2)}` : ''}</td>
            <td className='shrink text-right'>{`${t('Global:currencySymbol')}${h.balance.toFixed(2)}`}</td>
            <td className='shrink'>{h.billNumber}</td>
            <td className='shrink'>{h.expiry ? h.expiry.toAbbrDateTimeString(timeFormat, dateFormat, t) : action}</td>
            <td className='expand'>{h.void ? <span className='label label-danger'>{t('Global:void')}</span> : null}</td>
        </tr>
    }
}

export default CustomerCreditRow;